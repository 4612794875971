import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import { Flex, Box } from '../../../components/common/Grid'
import Layout from '../../../components/theme/Layout'
import Footer from '../../../components/theme/Footer'
import Header from '../../../components/theme/Header'
import Container from '../../../components/common/Container'
import RenderBlock from '../../../components/blocks/RenderBlock'
import Offers from '../../../components/common/Offers'

import house from '../../../assets/images/house.png'

const PageHeader = styled.section`
  ${tw`bg-light-blue py-20 pb-32`}
  margin-bottom:-80px;

  @media screen and (max-width: 40em) {
    ${tw`py-10 pb-20`}
    margin-bottom:-60px;
  }
`

const HouseImage = styled.img`
  max-width: 140px;
`

const H1 = styled.h1`
  ${tw`font-montserrat text-4xl font-light text-black leading-tight m-0`}
  max-width: 350px;

  @media screen and (max-width: 40em) {
    ${tw`mb-6`}
  }
`

const Subtitle = styled.div`
  ${tw`font-raleway font-medium text-lg leading-loose text-blog-text`}

  max-width: 500px;
  margin-left: auto;

  p {
    margin: 0px;
  }

  @media screen and (min-width: 40em) {
    ${tw`m-0 my-5`}
  }
`

const Products = ({ data }) => (
  <Layout
    meta={data.defaultPage.meta}
    urls={data.defaultPage.urls}
    locale='en'
  >
    <Header
      urls={data.defaultPage.urls}
      locale='en'
    />

    <PageHeader>
      <Container>
        <Flex
          alignItems="center"
          flexWrap='wrap'
        >
          <Box width={[
              1,
              1,
              1,
              1/2
            ]}
          >
            <Flex
              alignItems={[
                "flex-start",
                "flex-start",
                "flex-start",
                "center"
              ]}
              flexDirection={[
                'column',
                'column',
                'column',
                'row'
              ]}
            >
              <Box flex='0 0 auto'>
                <HouseImage src={house} alt="" />
              </Box>

              <Box
                ml={[
                  0,
                  0,
                  4,
                  4
                ]}
                mt={[
                  4,
                  4,
                  0,
                  0
                ]}
              >
                <H1 dangerouslySetInnerHTML={{ __html: data.defaultPage.title_html }} />
              </Box>
            </Flex>
          </Box>

          <Box width={[
              1,
              1,
              1,
              1/2
            ]}
          >
            <Subtitle dangerouslySetInnerHTML={{ __html: data.defaultPage.subtitle }} />
          </Box>
        </Flex>
      </Container>
    </PageHeader>

    <RenderBlock blocks={data.defaultPage.blocks} />

    <Footer />
    <Offers />
  </Layout>
)

export const query = graphql`
query {
  defaultPage(guid: {eq: "08421dc2-480c-4c99-b6d5-834990b6fe7f"}, locale: {eq:"en"}) {
    title_html
    subtitle
    description
    blocks {
      __typename
      ...productCategoriesBlockFragment
      ...imageLeftTextRightBlockFragment
      ...imageRightTextLeftBlockFragment
      ...productLandingBlockFragment
    }
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default Products
